<template>
	<el-dialog title="查看随访详情" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false">
		<el-form label-position="top" :model="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="随访日期" class="w50 mr16">
				<el-input v-model.trim="ruleForm.visitDate" disabled></el-input>
			</el-form-item>
			<el-form-item label="随访人员" class="w50 mr0">
				<el-input v-model.trim="ruleForm.visitName" disabled></el-input>
			</el-form-item>
			<el-form-item label="家庭信息" class="w50 mr16">
				<el-input v-model.trim="ruleForm.familyName" disabled></el-input>
			</el-form-item>
			<el-form-item label="随访人（所属团队）" class="w50 mr0">
				<el-input v-model.trim="ruleForm.healthServiceGroup" disabled></el-input>
			</el-form-item>
			<el-form-item label="联系电话">
				<el-input v-model.trim="ruleForm.phoneNumber" disabled></el-input>
			</el-form-item>
			<el-form-item label="随访记录">
				<el-input v-model.trim="ruleForm.visitHistory" disabled type="textarea" autosize></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="createdVisible = false">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			ruleForm: {},
			createdVisible: false,
		};
	},
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.visitName = data.visitor?.title || '-';
			this.ruleForm.familyName = data.household?.title || '-';
			this.ruleForm.healthServiceGroup = data.healthServiceGroup?.title || '-';
			this.ruleForm.visitHistory = '生活习惯：' + data.livingHabit + '。\n治疗效果：' + data.treatmentEffect + '。';
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>